import request from '@/utils/request'

// 首页客户统计数据
export function findHomeAgentsStatistics() {
  return request({
    url: `/home/agents_statistics`,
    method: 'get'
  })
}

// 首页最近通知消息列表
export function findHomeRecentNotifications(params) {
  return request({
    url: `/home/recent_notifications`,
    method: 'get',
    params: params
  })
}

// 首页卡片统计数据
export function findHomeCardsCountStatistics() {
  return request({
    url: `/home/cards_count_statistics`,
    method: 'get'
  })
}

// 首页本月卡运营数据统计数据
export function findHomeCurrentMonthOperationalCardsStatistics() {
  return request({
    url: `/home/current_month_operational_cards_statistics`,
    method: 'get'
  })
}

// 首页流量使用趋势统计数据
export function findHomeFlowUsageStatistics() {
  return request({
    url: `/home/flow_usage_statistics`,
    method: 'get'
  })
}

// 租户首页各项统计数据
//  订单数据统计
export function findTenantHomePageOrderStatistics() {
  return request({
    url: `/tenant/home_page/home_page_order`,
    method: 'get'
  })
}

// 任务统计
export function findTenantTaskStatistics() {
  return request({
    url: `/tenant/home_page/task_statistics`,
    method: 'get'
  })
}
// 厅房数据统计
export function findTenantHallDataStatisticsStatistics() {
  return request({
    url: `/tenant/home_page/hall_data_statistics`,
    method: 'get'
  })
}
// 其他数据统计
export function findTenantOtherDataStatistics() {
  return request({
    url: `/tenant/home_page/other_data_statistics`,
    method: 'get'
  })
}

// 集团首页各项统计数据
//  订单数据统计
export function findSystemHomePageOrderStatistics() {
  return request({
    url: `/system/home_page/home_page_order`,
    method: 'get'
  })
}

// 任务统计
export function findSystemTaskStatistics() {
  return request({
    url: `/system/home_page/task_statistics`,
    method: 'get'
  })
}
// 厅房数据统计
export function findSystemHallDataStatisticsStatistics() {
  return request({
    url: `/system/home_page/hall_data_statistics`,
    method: 'get'
  })
}
// 其他数据统计
export function findSystemOtherDataStatistics() {
  return request({
    url: `/system/home_page/other_data_statistics`,
    method: 'get'
  })
}

