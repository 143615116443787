<template>
  <div class="title">
    <div class="custom-flex custom-items-center">
      <div class="icon" />
      <span>负责人任务数量排名</span>
    </div>
    <div
      ref="responsiblePersonTaskStatisticsChart"
      style="height: 300px;"
    />
  </div>
</template>

<script>
import { findTenantTaskStatistics } from '@/api/home'
export default {
  name: 'ResponsiblePersonTask',
  computed: {
    chartOption() {
      return {
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            let relVal = this.$lodash.truncate(params[0].name, { length: 9 })
            for (let i = 0; i < params.length; i++) {
              relVal += '<br/>' + params[i].marker + params[i].name +
                  '&nbsp;&nbsp;' + params[i].value + '个'
            }
            return relVal
          }
        },
        grid: {
          x: 60,
          y: 50
          // x2: 20,
          // y2: 0
        },
        xAxis: {
          type: 'value',
          minInterval: 1,
          splitNumber: 3 // 段数是3
        },
        yAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            formatter: (params) => {
              return this.$lodash.truncate(params, { length: 6 })
            }
          }
        },
        series: [{
          type: 'bar',
          barMaxWidth: 40,
          name: '频率'
        }]
      }
    }
  },
  data() {
    return {
      chartData: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findTenantTaskStatistics().then(res => {
        if (res.code === 0) {
          const taskData = res.data.task_ranking || []
          for (let i = 0; i < taskData.length; i++) {
            const data = { name: taskData[i].name, value: taskData[i].number }
            this.chartData.push(data)
          }
          this.drawChart()
        }
      })
    },
    // 绘制图表
    drawChart() {
      this.chart ||= this.$echarts.init(this.$refs.responsiblePersonTaskStatisticsChart, 'echarts_custom')
      this.chartOption.yAxis.data = this.$lodash.map(this.chartData, 'name')
      this.chartOption.series[0].data = this.$lodash.map(this.chartData, 'value')
      // 全部重新加载-解决tooltip.formatter params不更新的问题
      this.chart.setOption(this.chartOption, true)
      // 添加表格自适应
      window.addEventListener('resize', () => {
        this.chart.resize()
      })
    }
  },
  beforeDestroy() {
    // 移除chart表格自适应监听
    window.removeEventListener('resize', () => {
      this.chart.resize()
    })
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 16px;
  height: auto;

  .icon {
    margin: 3px 3px 0 0;
    width: 3px;
    height: 15px;
    background-color: @primary-color;
  }
}

</style>

